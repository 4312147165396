import { AuthenticationService } from '@services/authentication/authentication.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoggedInUserService } from '@services/loggedInUser/logged-in-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private loggedInUserService: LoggedInUserService
  ) {
  }

  canActivate(): boolean {
    const loggedInUser = this.loggedInUserService.getLoggedInUser();
    if (loggedInUser) {
      this.router.navigate(['backend/search']).then();
      return true;
    } else {
      this.authenticationService.logout();
      return true;
    }
  }
}
