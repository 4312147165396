import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EncryptDecryptService } from '../encrypt-decrypt.service';
import { environment } from '@env';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class LoggedInUserService {
  urlProfile = environment.apiUrl + 'profile';
  urlAgencies = environment.apiUrl + 'agency-by-mail';
  private currentAgency: string = environment.agency;
  public $loggedInUserUpdated = new Subject<any>();
  private loggedInUser: any = null;
  private token: string;

  public flags: any[] = [
    { name: 'Español', image: 'assets/images/flags/es.png', lang: 'es' },
    { name: 'English', image: 'assets/images/flags/en.png', lang: 'en' },
    { name: 'Ruso', image: 'assets/images/flags/ru.png', lang: 'ru' },
    { name: 'Italiano', image: 'assets/images/flags/it.png', lang: 'it' }
  ];

  constructor(
    private httpClient: HttpClient,
    private encryptService: EncryptDecryptService
  ) {
    this.loggedInUser = this._getDataFromStorage('user')?.profile;
    if (this.currentAgency === 'russia') {
      this.flags = [
        { name: 'Español', image: 'assets/images/flags/es.png', lang: 'es' },
        { name: 'Inglés', image: 'assets/images/flags/en.png', lang: 'en' },
        { name: 'Ruso', image: 'assets/images/flags/ru.png', lang: 'ru' }
      ];
    }
  }

  public setNewProfile(profile: any) {
    const dataValue = this._getDataFromStorage('user') || {};
    dataValue.profile = Object.assign(dataValue?.profile, profile);
    this._setDataToStorage('user', JSON.stringify(dataValue));
    this.loggedInUser = dataValue.profile;
    this.$loggedInUserUpdated.next(this.loggedInUser);
  }

  public getLanguage() {
    return JSON.parse(localStorage.getItem('language'));
  }

  public getLoggedInUser(): any {
    let data = this._getDataFromStorage('user');
    data = data ? data.profile : null;
    return data;
  }

  public getTokenOfUser(): any {
    let data = this._getDataFromStorage('user');
    data = data ? data.token || this.token : null;
    return data;
  }

  public setLoggedInUser(user: any) {
    this.loggedInUser = user;
  }

  public saveAccountStorage(token: string): void {
    const hashedPass = this.encryptService.encrypt(token);
    localStorage.setItem('user', hashedPass);
  }

  public updateUserProfile(user: any) {
    const dataUser = this._getDataFromStorage('user');

    let tempData = dataUser || {};
    if (user) {
      tempData = { ...tempData, ...user };
    } else {
      tempData = null;
    }

    const dataString = JSON.stringify(tempData);
    this._setDataToStorage('user', dataString);
    this.loggedInUser = tempData?.profile;
    this.$loggedInUserUpdated.next(this.loggedInUser);
  }

  processPermission(user: any) {
    const permissionNamesArray: any[] = user?.permissionNames || [];
    const Permission = user?.Permissions || {};
    permissionNamesArray?.forEach((x: string) => {
      Permission[x] = true;
    });
    user.Permissions = { ...Permission };
    return user;
  }

  public hasPermissions(...args) {
    const operation = args?.[args?.length - 1] === '||' ? '||' : '&&';
    const initial = operation === '||' ? 0 : 1;
    return (
      args.reduce((acc, curr) => {
        return this.loggedInUser?.Permissions[curr];
      }, initial) || false
    );
  }

  public getLanguages() {
    return this.flags;
  }

  public _getDataFromStorage(key: string) {
    const data = localStorage.getItem(key);
    if (data === undefined || data === '')
      return undefined;

    try {
      const dataOutput = this.encryptService.decrypt(data);
      return JSON.parse(dataOutput);
    } catch (error) {
      localStorage.removeItem(key);
      return undefined;
    }
  }

  public _setDataToStorage(key: string, stringData: string) {
    try {
      const dataOutput = this.encryptService.encrypt(stringData || '');
      localStorage.setItem(key, dataOutput);
    } catch (error) {
      alert(error);
    }
  }

  public getAgencyId() {
    const user = this.getLoggedInUser();
    return user?.AgencyUser?.[0]?.AgencyId;
  }

  public getAgency() {
    const user = this.getLoggedInUser();
    return user?.AgencyUser?.[0]?.Agency;
  }

  public setAgency(agency: any) {
    const user = this.getLoggedInUser();
    try {
      user.AgencyUser[0].Agency = agency;
      this.updateUserProfile({ profile: user });
    } catch (error) {
      alert(`Error editando agencia:${error}`);
    }
  }

  public setToken(token) {
    this.token = token;
  }

  public getToken() {
    return this.token;
  }

  getProfile() {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('skip', 'true');

    return this.httpClient.get<any>(this.urlProfile, {
      params: httpParams
    });
  }

  getAgenciesByMail(email: string) {
    const base64EncodedPw = btoa(email);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + base64EncodedPw
      })
    };

    return this.httpClient.get<any>(this.urlAgencies, httpOptions);
  }

  public UrlAfterLogin(): string | boolean {
    const perm = this.loggedInUser?.Permissions;

    if (perm?.getBackendReservation || this.loggedInUser?.role === 'admin') {
      // return 'backend/search';
      // return 'backend/dashboard';
      return 'backend/reserve';
    }
    if (perm?.getContract || perm?.postContract || perm?.patchContract) {
      return 'backend/contract';
    }
    if (perm?.getCalendar) {
      return 'backend/calendar';
    }
    if (perm?.getEvent || perm?.postEvent || perm?.patchEvent) {
      return 'backend/event';
    }
    if (perm?.getAgency || perm?.postAgency || perm?.patchAgency) {
      return 'backend/agency';
    }
    if (perm?.getRegion || perm?.postRegion || perm?.patchRegion) {
      return 'backend/region';
    }
    if (perm?.getBorrower || perm?.postBorrower || perm?.patchBorrower) {
      return 'backend/borrower/borrowers';
    }
    if (perm?.getPayment || perm?.postPayment || perm?.patchPayment) {
      return 'backend/payments';
    }
    // Repostes
    if (perm?.getSalesReport) {
      return 'backend/reports/sales';
    }
    if (perm?.getConciliationReport) {
      return 'backend/reports/conciliation';
    }
    // nomencladores
    if (perm?.getPlace || perm?.postPlace || perm?.patchPlace) {
      return 'backend/nomenclator/place';
    }
    if (perm?.getOfferCode || perm?.postOfferCode || perm?.patchOfferCode) {
      return 'backend/nomenclator/offer-code';
    }
    if (perm?.getBusStop || perm?.postBusStop || perm?.patchBusStop) {
      return 'backend/nomenclator/bus-stop';
    }
    if (perm?.getBankAccount || perm?.postBankAccount || perm?.patchBankAccount) {
      return 'backend/nomenclator/bank-accounts';
    }
    if (perm?.getCategoryGroup || perm?.postCategoryGroupe || perm?.patchCategoryGroupe) {
      return 'backend/nomenclator/category-group';
    }
    if (perm?.getCountry || perm?.postCountry || perm?.patchCountry) {
      return 'backend/nomenclator/country';
    }
    if (perm?.getProvince || perm?.postProvince || perm?.patchProvince) {
      return 'backend/nomenclator/province';
    }
    if (perm?.getMunicipality || perm?.postMunicipality || perm?.patchMunicipality) {
      return 'backend/nomenclator/municipality';
    }
    if (perm?.getSubSymbolicPlace || perm?.postSubSymbolicPlace || perm?.patchSubSymbolicPlace) {
      return 'backend/nomenclator/symbolic-place';
    }
    if (perm?.getSupplement || perm?.postSupplement || perm?.patchSupplement) {
      return 'backend/nomenclator/supplement';
    }
    if (perm?.getServices || perm?.postServices || perm?.patchServices) {
      return 'backend/nomenclator/service';
    }
    if (perm?.getSchedules || perm?.postSchedules || perm?.patchSchedules) {
      return 'backend/nomenclator/circuit-items';
    }
    if (perm?.getSubPublics || perm?.postSubPublics || perm?.patchSubPublics) {
      return 'backend/nomenclator/public';
    }
    if (perm?.getThemes || perm?.postThemes || perm?.patchThemes) {
      return 'backend/nomenclator/sub-theme';
    }
    if (perm?.getLanguage || perm?.postLanguage || perm?.patchLanguage) {
      return 'backend/nomenclator/language';
    }
    if (perm?.getFleet || perm?.postFleet || perm?.patchFleet) {
      return 'backend/nomenclator/fleet';
    }
    // configuracion
    if (perm?.getConfig || perm?.postConfig || perm?.patchConfig) {
      return 'backend/configurations/admin-cancellations';
    }


    if (perm?.getRol || perm?.postRol || perm?.patchRol) {
      return 'backend/role';
    }
    if (perm?.getUser || perm?.postUser || perm?.patchUser) {
      return 'backend/users';
    }
    if (perm?.getReferred || perm?.postReferred || perm?.patchReferred) {
      return 'backend/referred-users';
    }
    if (perm?.getTraedores || perm?.postTraedores || perm?.patchTraedores) {
      return 'backend/carrier-men';
    }
    if (perm?.getBorrower) {
      return 'backend/borrower/borrowers';
    }
    if (perm?.getOperation) {
      return 'backend/borrower/operations';
    }
    // unica ruta que no necesita permisos.
    return 'backend/perfil';
  }
}
